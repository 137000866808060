import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import Spinner from './Components/spinner';
import './assets/scss/style.scss';
import './assets/custom.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './index.css';

// import App from './Components/App';

const App = lazy(
	() =>
		new Promise(resolve => {
			setTimeout(() => resolve(import('./Components/App')), 1000);
		}),
);
ReactDOM.render(
	<Suspense fallback={<Spinner />}>
		<App />,
	</Suspense>,
	document.getElementById('root'),
);
